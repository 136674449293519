//import { Fragment } from 'react'
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'

const navigation = [
	{ name: 'Home', href: '/' },
	{ name: 'About', href: '/about' },
]

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function Example() {
	return (
		<Disclosure as="nav">
			{
				({ open }) => (
					<>
						<div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
							<div className="relative flex h-16 items-center justify-between">
								<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
									{/* Mobile menu button*/}
									<Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-slate-700 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
										<span className="sr-only">Open main menu</span>
										{open ? (
											<XMarkIcon className="block h-6 w-6" aria-hidden="true" />
										) : (
											<Bars3Icon className="block h-6 w-6" aria-hidden="true" />
										)}
									</Disclosure.Button>
								</div>
								<div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
									<div className="flex flex-shrink-0 items-center">
										<img
											className="block h-8 w-auto lg:hidden"
											src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
											alt="Your Company"
										/>
										<img
											className="hidden h-8 w-auto lg:block"
											src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
											alt="Your Company"
										/>
									</div>
								</div>
								<div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
									<div className="hidden sm:ml-6 sm:block">
										<div className="flex space-x-4">
											{navigation.map((item) => (
												<NavLink
													key={item.name}
													to={item.href}
													// className={classNames(
													// 	splitLocation[1] === "item.name" ? 'text-sky-500' : 'text-slate-700 hover:underline',
													// 	'px-3 py-2 rounded-md text-sm font-medium'
													// )}
													className='px-3 py-2 rounded-md text-sm font-medium text-slate-700 hover:underline'
												>
													{item.name}
												</NavLink>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>

						<Disclosure.Panel className="sm:hidden">
							<div className="space-y-1 px-2 pt-2 pb-3">
								{navigation.map((item) => (
									<NavLink
										key={item.name}
										to={item.href}
										className='px-3 py-2 rounded-md text-sm font-medium text-slate-700 block'
									>
										{item.name}
									</NavLink>
								))}
							</div>
						</Disclosure.Panel>
					</>
				)
			}
		</Disclosure >
	)
}
