import Hero from '../components/Hero'
import Section from '../components/Section'

function Title(props) {
    return (
        <h2 class="text-3xl sm:text-4xl text-slate-900 font-extrabold tracking-tight">
            {props.children}
        </h2>
    )
}

function P(props) {
    return (
        <p class="mt-4 max-w-3xl text-lg">{props.children}</p>
    )
}

export default function Home() {
    return (
        <>
            <Hero />
            <Section color='bg-gray-100'>
                <Title>Who we are...</Title>
                <P>Learners, Teachers & Revolutionists</P>
                <ul class="ml-5 mt-1 list-disc">
                    <li>Agricultural Innovative Research & Development</li>
                    <li>Sustainable Smart Farming</li>
                </ul>
            </Section>
        </>
    );
}
