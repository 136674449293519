import './App.css';
import * as React from "react";

import { Routes, Route, Outlet } from "react-router-dom";
import Navbar from './components/Navbar';
import NoMatch from './components/NoMatch';
import Home from './Pages/Home';
import About from './Pages/About';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <>
      <Navbar />
      <main className='antialiased font-poppins text-slate-500 bg-white'>
        <Outlet />
      </main>
    </>
  );
}

export default App;
