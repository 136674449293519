function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function Section(props) {
    return (
        <section
            className={classNames(
                props.color ? props.color : '',
                'mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-10'
            )}>
            {props.children}
        </section>
    );
}
