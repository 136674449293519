export default function Hero() {
    return (
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative mx-auto max-w-5xl pt-20 pb-16 sm:pt-24 lg:pt-32">
                <h1 className="text-center text-4xl font-extrabold tracking-tight text-slate-900 sm:text-5xl lg:text-6xl">
                    O2 Park MyAdda Self Sustainable Environment & Economy
                </h1>
                <p className="mx-auto mt-6 max-w-3xl text-center text-lg text-slate-600">
                    A brief info about As Sergey and I wrote in the original founders letter 11 years ago, “Google is not a conventional company. We do not intend to become one.”
                </p>
                <div className="mt-6 flex justify-center space-x-6 text-sm sm:mt-10">
                    <a
                        className="flex h-12 w-full items-center justify-center rounded-lg bg-slate-900 px-6 font-semibold text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 sm:w-auto"
                        href="/about"
                    >
                        Learn more
                    </a>
                </div>
            </div>
        </div>
    );
}
