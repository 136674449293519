export default function Hero() {
    return (
        <div class="relative mx-auto max-w-5xl pt-20 sm:pt-24 lg:pt-32">
            <h1 class="text-center text-4xl font-extrabold tracking-tight text-slate-900 sm:text-5xl lg:text-6xl">
                404
            </h1>
            <p class="mx-auto mt-6 max-w-3xl text-center text-lg text-slate-600">
                No Page Found.
            </p>
        </div>
    );
}
